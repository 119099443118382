import useSWR from 'swr'
import './App.css';
const url = process.env.REACT_APP_API;

function App() {
  const { data } = useSWR('/api/user')
  const listUser = useSWR('/api/hac_api/users?page=1&page_size=20')
  const clickMe = () => {
    fetch('/api/hac_api/users/200000310033/scopes', { 
      method: 'PUT', 
      body: '{"scopes":["ic_api.announcement.write"]}',
      headers: {
        'Content-Type' : 'application/json'
      }
      })
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>{data?.name}</h1>
        { data?.name && <a href='/api/logout'>Logout</a> }
        { !(data?.name) && <a href='/api/login'>Login</a> }  

        <button onClick={clickMe}>Update</button>
      </header>
    </div>
  );
}

export default App;
